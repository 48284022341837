
// import LogoPath from './Images/nithin_logo.png';
// export const ApiUrl = "https://simpfob2btestapi.simpfo.in";

import LogoPath from './Images/halliangadi_logo.png';
export const ApiUrl = "https://halliangadiapi.simpfo.in";

// import LogoPath from './Images/Halli-Organics-Logo.png';
// export const ApiUrl = "https://halliorganicsapi.simpfo.in";

// import LogoPath from './Images/nithin_logo.png';
// export const ApiUrl = "https://nithinb2capi.simpfo.in";

//export const ApiUrl = "http://localhost:3100";
export const BranchId = 1;
export const Logo = LogoPath;
//Note: Change URL, Logo and FIREBASE CONFIG