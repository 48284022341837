import React, { Fragment, useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Avatar from '@material-ui/core/Avatar';
import Image from "./Images/login_pic.jpeg"
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
//import firebase from "@firebase/app";
import "@firebase/auth";
import { useAuth } from './Auth'
import { useStore } from "./Store";
import { ApiUrl, BranchId } from './AppConstants';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${Image})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(18, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        alignItems: 'center',
    },
    iconStyle: {
        border: "1px solid"
    },
    formPosition: {
        width: '65%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },

    btnStyling: {
        margin: theme.spacing(3, 0, 2),
    }
}));

export const Login = (props) => {

    const classes = useStyles();
    const [showOtpInput, setShowOtpInput] = useState(false)
    const [phone, setPhone] = useState();
    const [Otp, setOtp] = useState();
    const [password, setPassword] = useState('123456');
    const [OtpRequestInProgress, setOtpRequestInProgress] = useState(false);
    const [errors] = useState({})
    const { history } = props;
    const { setLoginState } = useAuth()
    const { setUserStore } = useStore();
    const { setTokenStore } = useStore();
    const { setAddressStore } = useStore();
    const { defaultAddressStore, setDefaultAddressStore } = useStore();
    const { setAppSettingsStore } = useStore();

    useEffect(() => {
        if (window.localStorage.token) {
            const payload = {
                "mobilenumber": window.localStorage.phone,
                "logintype": "social",
                "device_token": ""
            }
            console.log(payload, 'payload')

            fetch(ApiUrl + '/auth/login',
                {
                    mode: 'cors',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                .then(result => {
                    console.log(result, 'fetch in login')
                    if (result.status === 404) {
                        console.log('result is 404')
                    } else if (result.status !== 200) {
                        console.log(result)
                        console.log('result is not 200')
                    } else {
                        result.json().then(body => {
                            if (body.success !== true) {
                                console.log('request failed', body)
                            }
                            else {
                                console.log(body, 'response')
                                var user = body.data[0]
                                var address = body.address
                                console.log('address from backend', address)
                                setLoginState(true)
                                window.localStorage.setItem('token', body.token)
                                window.localStorage.setItem('phone', user.Phone)
                                setUserStore({ user, type: 'User' })
                                setAddressStore({ address, type: 'Address' })
                                setDefaultAddressStore({ defaultAddress: body.defaultAddress[0] })
                                setTokenStore({ token: user.token })
                                setAppSettingsStore({ CompanyName: user.CompanyName })
                                document.title = user.CompanyName;
                                window.localStorage.setItem('IsB2b', user.IsB2b)
                                console.log(history, 'history object')
                                history.push('/')
                                    //console.log('defaultAddress===>', defaultAddressStore.defaultAddress)

                                    

                            }
                        });
                    }
                })
                .catch(error => {
                    console.log("error from server", JSON.stringify(error, Object.getOwnPropertyNames(error)));
                });
        }

    })

    const handlePhoneNumberChange = (event) => {
        setPhone(event.target.value)
    }

    const handleOtpChange = (e) => {
        setOtp(e.target.value)
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value)
    }

    // const handleRequestOTP = (event) => {
    //     event.preventDefault()
    //     setShowOtpInput(true);

    //     if (validatePhone()) {
    //         window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('RequestOtpButton', {
    //             'size': 'invisible',
    //             'callback': (response) => {
    //                 // reCAPTCHA solved, allow signInWithPhoneNumber.
    //                 onSignInSubmit();
    //             },
    //             defaultCountry: "IN"
    //         });
    //     }
    //     //console.log(errors, 'errors in handle otp')
    // }

    //Firebase Phone Auth start
    const confitureRecaptcha = () => {
        const auth = getAuth();
        window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                onSignInSubmit();
                console.log("Recaptcha varified");
            },
            defaultCountry: "IN"
        }, auth);
    }

    const onSignInSubmit = (e) => {
        setOtpRequestInProgress(true);
        e.preventDefault();
        confitureRecaptcha();
        const phoneNumber = '+91' + phone;
        const appVerifier = window.recaptchaVerifier;
        const auth = getAuth();
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                alert('OTP has been sent');
                setShowOtpInput(true);
                setOtpRequestInProgress(false);
                //setOtp('');            
                // ...
            }).catch((error) => {
                // Error; SMS not sent
                // ...
                alert('Error while sending OTP');
                setOtpRequestInProgress(false);
                console.log(error);
            });
    }

    const SignIn = (e) => {
        setOtpRequestInProgress(true);
        e.preventDefault();
        const code = Otp;
        window.confirmationResult.confirm(code).then((result) => {
            // User signed in successfully.
            //alert('Successfully verified OTP');
            //console.log(JSON.stringify(user));
            handleLogin('otp');
            // ...
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
            alert('Error while verifying OTP');
            setOtpRequestInProgress(false);
        });
    }

    const SignInPassword = (e) => {
        //setOtpRequestInProgress(true);
        e.preventDefault(); 
        handleLogin('password');
        //e.preventDefault(); 
        //setOtpRequestInProgress(false);       
        
    }
    //Firebase Phone Auth end

    // const validatePhone = () => {
    //     console.log(phone)
    //     var temp = []
    //     temp.phone = phone ? "" : "phone is required"
    //     if (!temp.phone) temp.phone = phone.length === 10 ? "" : "phone number should be 10 digits"
    //     setErrors({ ...temp })

    //     return Object.values(temp).every(param => param === "")
    // }

    // const onSignInSubmit = (e) => {
    //     const appVerifier = window.recaptchaVerifier;
    //     firebase.auth().signInWithPhoneNumber('+91' + phone, appVerifier)
    //         .then((confirmationResult) => {
    //             // SMS sent. Prompt user to type the code from the message, then sign the
    //             // user in with confirmationResult.confirm(code).
    //             setShowOtpInput(true)
    //             window.confirmationResult = confirmationResult;
    //             setFirebaseConfirmationResult(confirmationResult)
    //         }).catch((error) => {
    //             console.log(error, 'error in sending sms');
    //             // window.recaptchaVerifier.render().then(function(widgetId) {
    //             //     grecaptcha.reset(widgetId);
    //             //   });

    //         });
    // }

    const editPhoneFromVerifyOtp = () => {
        setShowOtpInput(false)

    }

    const handleLogin = async (mode) => {
        var payload ='';

        if (true) {

            if(mode === 'otp') {
            payload = {
                "mobilenumber": phone,
                "logintype": "social",
                "device_token": "",
                "createNewCustomerLogin": true,
                "branchId": BranchId
            };}
            else {
                payload = {
                    "mobilenumber": phone,
                    "logintype": "custom",
                    "password": password,
                    "device_token": "",
                    "createNewCustomerLogin": true,
                    "branchId": BranchId
                };
            }

            await fetch(ApiUrl + '/auth/login',
                {
                    mode: 'cors',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                .then(result => {
                    console.log(result, 'fetch in login')
                    if (result.status === 404) {
                        console.log('result is 404')
                    } else if (result.status !== 200) {
                        console.log(result)
                        console.log('result is not 200')
                    } else {
                        result.json().then(body => {
                            if (body.success !== true) {
                                console.log('request failed', body)
                                //setNoAccessText('You are not authorised to accesss this website. Please contact admin')
                                // history.push('/noauth')
                            }
                            else {
                                setLoginState(true)
                                console.log(body, 'response')
                                var user = body.data[0]
                                var address = body.address
                                console.log('address from backend', address)
                                window.localStorage.setItem('token', body.token)
                                window.localStorage.setItem('phone', user.Phone)
                                window.localStorage.setItem('loginState', true)
                                setAppSettingsStore({ CompanyName: user.CompanyName })
                                document.title = user.CompanyName
                                window.localStorage.setItem('IsB2b', user.IsB2b)
                                setUserStore({ user, type: 'User' })
                                setAddressStore({ address, type: 'Address' })
                                setDefaultAddressStore({ defaultAddress: body.defaultAddress[0] })
                                console.log('defaultAddress===>', defaultAddressStore.defaultAddress)
                                setTokenStore({ token: body.token })

                                history.push({
                                    pathname: "/",
                                    state: { token: body.token }
                                })
                            }
                        });
                    }
                })
                .catch(error => {
                    console.log("error from server", JSON.stringify(error, Object.getOwnPropertyNames(error)));
                });


            // 
            //     firebaseConfirmationResult.confirm(passwordFromUser).then((result) => {
            //         setLoginState(true)
            //         fetchUser()
            //         history.push('/')
            //     }).catch((error) => {
            //         console.log(error,'otp mismatch')
            //         alert('Otp doesnt match, try again')
            //     });
        }
        // console.log(errors, 'errors')
    }

    return (
        <div>
            {/* <Header title='Organic House' history={props.history} /> */}
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5"> Sign in </Typography>

                        <form className={classes.formPosition}>

                            <Fragment>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    required
                                    id="phone"
                                    label="Phone number (10 Digits.)"
                                    name="phone"
                                    onChange={handlePhoneNumberChange}
                                    error={errors.phone}
                                    helperText={errors.phone}
                                    defaultValue={phone}
                                    type="number"
                                    disabled={showOtpInput}
                                />

                                <div id="sign-in-button"> </div>
                                {showOtpInput ?
                                    <Button
                                        id='RequestOtpButton'
                                        className={classes.btnStyling}
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={editPhoneFromVerifyOtp}
                                    >
                                        Edit Phone
                                    </Button>
                                    : ''
                                    // <Button
                                    //     id='RequestOtpButton'
                                    //     className={classes.btnStyling}
                                    //     fullWidth
                                    //     type="submit"
                                    //     variant="contained"
                                    //     color="primary"
                                    //     onClick={onSignInSubmit}
                                    //     disabled={OtpRequestInProgress}
                                    // >
                                    //     Request OTP
                                    // </Button>
                                }
                                {/* <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    required
                                    id="otp"
                                    label="OTP (6 Digits)"
                                    name="otp"
                                    onChange={handleOtpChange}
                                    error={errors.Otp}
                                    helperText={errors.Otp}
                                    defaultValue=''
                                    placeholder="OTP"
                                    type="number"
                                    disabled={!showOtpInput}
                                /> */}
                                 {/* <TextField
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    required
                                    id="password"
                                    label="Password (Default: 123456)"
                                    name="password"
                                    onChange={handlePasswordChange}
                                    error={errors.password}
                                    helperText={errors.password}
                                    defaultValue='123456'
                                    placeholder="Default: 123456"
                                    type="password"     
                                    style={{ width: '0px', height: '0px' }}                               
                                /> */}
                                {/* <Button
                                    id='RequestOtpButton'
                                    className={classes.btnStyling}
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={SignIn}
                                    disabled={!showOtpInput || OtpRequestInProgress}
                                >
                                    Verify OTP
                                </Button>  */}
                                <Button
                                    id='RequestOtpButton'
                                    className={classes.btnStyling}
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={SignInPassword}                                    
                                >
                                    Sign In
                                </Button> 
                                </Fragment>

                        </form>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
